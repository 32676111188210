.FormContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}


@media(min-width: 700px) {
  .FormContainer{
    margin-top: 66px;
  }
}