.ErrorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorTitle {
  margin-top: 143px;
  max-width: 320px;
  font-family: 'CooperBT';
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
}

.Description {
  margin-top: 15px;
  max-width: 290px;
  font-family: 'ModernEra';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6d7278;
}

.ErrorMessage{
  display: flex;
  margin-top: 20px;
  color: red;
  text-align: center;
  font-family: 'ModernEra';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e02020;
}

.FormSubmitButton {
  display: flex;
  flex-direction: column;
  outline: none;
  margin-top: 30px;
  min-width: 156px;
}

.SubmitButton{
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  padding: 14px 30px 15px;
  margin: 30px 15px 0;
  font-family: 'ModernEra';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

@media(min-width: 400px){
  .ErrorTitle {
    margin-top: 175px;
  }
}

@media(min-width: 700px){
  .ErrorTitle {
    font-size: 45px;
    margin-top: 0;
    max-width: 100%;
  }


  .FormSubmitButton {
    margin-top: 15px;
  }

  .FormSubmitButton {
    flex-direction: row;
    margin: 0 15px 0;
  }
  
}

@media(max-width: 350px) {

}