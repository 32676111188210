
.ContainerApollo {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Apollo {
  position: fixed;
  width: 105%;
  z-index: -2;
  margin-top: -45px;
}


@media(min-width: 430px){
  .Apollo {
    margin-top: -25px;
  }
}

@media(min-width: 500px){
  .Apollo {
    margin-top: -33px;
  }

}

@media(min-width: 700px){
  .Apollo {
    align-self: flex-end;
    width: 676px;
    margin-top: -6px;
  }

  .SuccessTitle {
    font-size: 45px;
  }

  .ContainerApollo {
    height: 195px;
  }
}
