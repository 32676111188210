.ContainerBilling {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.Title {
  font-family: CooperBT;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #252525;
  margin: 0 5px;
}

.Description {
  display: flex;
  margin: 15px 10px 0;
  max-width: 467px;
  font-family: 'ModernEra';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.FormSubmitButton {
  outline: none;
  margin-top: 30px;
  min-width: 156px;
  margin-bottom: 200px;
}

.SubmitButton{
  outline: 0;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #0752f9;
  padding: 14px 30px 15px;
  font-family: 'ModernEra';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  max-width: 346px;
  width: 100%;
}

.LabelDropDown {
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "ModernEra";
  margin-bottom: 15px;
}

.Blue {
  color: #07f;
}

.SubDesc {
  display: flex;
  margin: 35px 10px 0;
  max-width: 467px;
  font-family: 'ModernEra';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.InvoiceText > p {
  font-weight: bold;
  font-family: 'ModernEra';
  font-size: 14px;
}

.InvoiceText a {
  outline:  none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.InvoiceText a:hover {
  color: #07f;
}

@media (max-width: 350px) {
	.FormContainer {
    max-width: 300px;
  }
}


@media(min-width: 700px) {

  .Title {
    font-size: 45px;
  }

  .ContainerBilling {
    margin-top: 64px;
  }
}

