.Logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

.Logo img {
  width: 144px;
  height: 32px;
}

.MainContainer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 15px 2px rgba(205, 205, 205, 0.5);
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 15px 2px rgba(205, 205, 205, 0.5);
  -moz-box-shadow:  0 10px 15px 2px rgba(205, 205, 205, 0.5);
}


@media (min-width: 700px) {

  .MainContainer {
    justify-content: space-between;
  }

  .Logo {
    padding-left: 120px;
  }

}